import { useRouter } from "next/router";
import styles from "./hero-section.module.scss";
import Animated from "@/components/animated";
import Text from "@/components/text";
import Button from "@/components/button";
import { ctaAllEvents, ctaEvents } from "@/lib/gtm";

const HeroSection = ({ title, subHeader, ctaButton1, ctaButton2, introVideo, backgroundColor }) => {
	const router = useRouter();

	const ctaButtonOnClick = (button) => {
		if (!!button.diagnosisType) {
			ctaEvents("hero", button.diagnosisType, button?.visitType === "Revisit");
			sessionStorage.setItem("currentUrl", router.asPath);
			router.push({
				pathname: "/doktor",
				query: {
					diagnosisTypeOverride: button.diagnosisType,
					...(button?.visitType === "Revisit" ? { revisit: true } : null),
				},
			});
			return;
		}

		if (!!button?.ctaButtonOpenNewTab) {
			window.open(button?.ctaButtonURL, "_blank");
		} else {
			if (button?.ctaButtonURL == "diagnoserna") {
				ctaAllEvents("hero");
			}
			router.push({
				pathname: button?.ctaButtonURL,
			});
		}
	};

	const resolveBackgroundColor = () => {
		if (backgroundColor?.toLowerCase() === "primary") {
			return styles.primaryBackgroundColor;
		} else if (backgroundColor?.toLowerCase() === "secondary") {
			return styles.secondaryBackgroundColor;
		} else if (backgroundColor?.toLowerCase() === "tertiary") {
			return styles.tertiaryBackgroundColor;
		} else {
			return styles.primaryBackgroundColor;
		}
	};

	return (
		<section className={`${styles.heroSection} ${resolveBackgroundColor()}`}>
			<div className="container">
				<div className="row">
					<div className={`col-sm-6 ${styles.leftContainer}`}>
						<section className={styles.textSection}>
							<div className={styles.textContainer}>
								<Animated animation="slideInLeft">
									{!!title && <Text tag="h1">{title}</Text>}
									{!!subHeader && <Text tag="body">{subHeader}</Text>}
								</Animated>
							</div>
							<div className={styles.buttonOneContainer}>
								{!!ctaButton1 && !!ctaButton1?.ctaButtonText && (
									<Animated animation="slideInDown" delay={600}>
										<Button
											text={ctaButton1.ctaButtonText}
											minimumWidth
											onClick={() => ctaButtonOnClick(ctaButton1)}
										/>
									</Animated>
								)}
							</div>
							<div>
								{!!ctaButton2 && !!ctaButton2?.ctaButtonText && (
									<Animated animation="slideInDown" delay={600}>
										<Button
											text={ctaButton2.ctaButtonText}
											type="secondary"
											minimumWidth
											onClick={() => ctaButtonOnClick(ctaButton2)}
										/>
									</Animated>
								)}
							</div>
						</section>
					</div>
					<div className={`col-sm-6 ${styles.rightContainer}`}>
						<section style={{ padding: "48px", textAlign: "center" }}>
							{!!introVideo && (
								<video
									src={introVideo.url}
									type={introVideo.mime}
									muted
									loop
									autoPlay
									className={styles.video}
									playsInline
								></video>
							)}
						</section>
					</div>
				</div>
			</div>
		</section>
	);
};

export default HeroSection;
