import React from "react";
import PropTypes from "prop-types";
import styles from "./pixelated-border.module.scss";

const PixelatedBorder = ({ flip, secondary }) => {
  return (
    <div
      className={
        styles.border +
        (flip ? " " + styles.flip : "") +
        (secondary ? " " + styles.secondary : "")
      }
    />
  );
};

PixelatedBorder.propTypes = {
  flip: PropTypes.bool,
  secondary: PropTypes.bool,
};

PixelatedBorder.defaultProps = {
  flip: false,
  secondary: false,
};

export default PixelatedBorder;
