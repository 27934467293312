import React from "react";
import Link from "@/components/link";
import Image from "../image";
import PropTypes from "prop-types";
import styles from "./read-more-button.module.scss";

const ReadMoreButton = ({url, text, ...props}) => {
  const className =
        (props.className ? props.className + " " : "") +
        styles.container;
  props.textColor = props.textColor ? props.textColor : 'black';

  return (
    <div className={`${className} 
    ${props.textColor?.toLowerCase() === "white" ? styles.textColorWhite: styles.textColorBlack}`}>
        <Link href={url || ''} variant="children">
            <div className={styles.flexContainer}>
                <div className={styles.leftContainer}>
                    {text}
                </div>
                <div className={styles.rightContainer}>
                    {props.textColor?.toLowerCase() === "black" && <Image width={50} height={50} className={styles.arrow} src='/icons/circle-arrow-right.svg' alt='Arrow right'/>}
                    {props.textColor?.toLowerCase() === "white" && <Image width={50} height={50} className={styles.arrow} src='/icons/circle-arrow-right-white.svg' alt='Arrow right'/>}
                </div>
            </div>
        </Link>
    </div>
  );
};

ReadMoreButton.propTypes = {
  url: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};

export default ReadMoreButton;
