import styles from "./list-section.module.scss";
import Text from "@/components/text";
import NumberedList from "@/components/numbered-list";
import Testimonial from "@/components/testimonial";


const ListSection = ({
 title,
 subtitle,
 listItem,
 testimonialsDeprecated,
 testimonialTextColor,
 testimonialBackgroundColor,
 tryggEHandelId
 }) => {

    return (
        <section className={styles.section}>
            <div className="container pt-3">
                <div className="row">
                    <div className="col-sm-6">
                        <section className={styles.firstSection}>
                            <section className={styles.numberedListSection}>
                                <div className={styles.centerOnSmallScreens}>
                                    <Text tag="h2" variant="h1">
                                        {title}
                                    </Text>
                                    <Text tag="subHeader">{subtitle}</Text>
                                </div>
                                <NumberedList
                                    data={listItem.map((item) => item.text)}
                                />
                            </section>
                            {tryggEHandelId && 
                                <div className={`teh-certificate ${styles.certificateLogo}`}></div>
                            }
                        </section>
                    </div>
                    <div className="col-sm-6 pt-3">
                        <section className={styles.secondSection}>
                            {!!testimonialsDeprecated && <Testimonial data={testimonialsDeprecated}
                                          testimonialTextColor={testimonialTextColor}
                                          testimonialBackgroundColor={testimonialBackgroundColor}
                            />}
                        </section>
                    </div>
                </div>
            </div>

        </section>
    );
};

export default ListSection;
