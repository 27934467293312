import React from 'react';
import PropTypes from 'prop-types';
import styles from './numbered-list.module.scss';
import Text from '../text';

const NumberedList = ({ data, listItemType }) => {
	return (
		<div>
			{data.map((text, index) => (
				<NumberedListItem
					index={index}
					key={text}
					text={text}
					listItemType={listItemType}
				/>
			))}
		</div>
	);
};

NumberedList.propTypes = {
	data: PropTypes.array.isRequired,
};

NumberedList.defaultProps = {
	data: [],
};

const renderListItemType = (listItemType, index) => {
	if (listItemType === 'Numbered') {
		return <div className={styles.number}>{index + 1}</div>;
	}

	if (listItemType === 'NonNumberedInverted') {
		return <div className={styles.numberInverted}></div>;
	}

	if (listItemType === 'NonNumbered' || listItemType === null || listItemType === undefined) {
		return <div className={styles.number}></div>;
	}
};

const NumberedListItem = ({ text, listItemType, index }) => {
	return (
		<div className={styles.numberedList}>
			{renderListItemType(listItemType, index)}
			<div className={styles.text}>
				<Text>{text}</Text>
			</div>
		</div>
	);
};

export default NumberedList;
