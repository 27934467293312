import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./carousel.module.scss";
import Text from "../text";
import ReadMoreButton from "@/components/read-more-button";
import Image from "@/components/image";

const Carousel = ({ title, data, image, illustrationQuoteListLink }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((ix) => (ix + 1) % data.length);
    }, 6000);
    return () => clearInterval(timer);
  }, [data]);

  const isImage = () => {
      return image?.url?.endsWith('.png') ||
      image?.url?.endsWith('.jpg') ||
      image?.url?.endsWith('.svg')
  }

  return (
    <div>
      <Text tag="h2" variant="h1">
        {title}
      </Text>
      <div className={styles.carousel}>
        <div className={styles.items}>
          <Item item={data[activeIndex]} />
        </div>
        <div>
            {!!illustrationQuoteListLink?.listLinkText && <ReadMoreButton
                url={illustrationQuoteListLink?.listLinkURL}
                text={illustrationQuoteListLink.listLinkText}
                textColor={'Black'}
            />}
        </div>
          {!!image && <div className={styles.mascotContainer}>
              {(!!image && isImage()) && <div className={styles.image}>
                  <Image
                      src={image.url}
                      alt="Mascot"
                  />
              </div>}
              {(!!image && !isImage()) && <video
                  src={image.url}
                  autoPlay
                  muted
                  playsInline
                  loop
                  className={styles.image}
              ></video>}
          </div>}
      </div>
    </div>
  );
};

const Item = ({ item }) => {
  return (
    <div className={styles.item} key={item.quote}>
      <Text>{item.quote}</Text>
      <div className="pt-2" />
      <Text tag="subHeader">{item.name}</Text>
    </div>
  );
};

Carousel.propTypes = {
  data: PropTypes.array.isRequired,
  image: PropTypes.object,
};

Carousel.defaultProps = {
  data: [],
};

export default Carousel;
