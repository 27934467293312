import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styles from "./testimonial.module.scss";
import Text from "../text";
import PixelatedBorder from "../pixelated-border";

const Testimonial = ({ data, testimonialTextColor, testimonialBackgroundColor }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      setActiveIndex((ix) => (ix + 1) % data.length);
    }, 6000);
    return () => clearInterval(timer);
  }, [data]);

  const resolveBackgroundColor = () => {
    if (testimonialBackgroundColor?.toLowerCase() === "primary") {
      return styles.backgroundColorPrimary
    } else if (testimonialBackgroundColor?.toLowerCase() === "secondary") {
      return styles.backgroundColorSecondary
    } else if (testimonialBackgroundColor?.toLowerCase() === "tertiary") {
      return styles.backgroundColorTertiary
    }
  }

  return (
    <div className={`${styles.testimonial} 
    ${testimonialTextColor?.toLowerCase() === "white" ? styles.textColorWhite : styles.textColorBlack}`}>
      <PixelatedBorder flip />
      <div className={`${styles.items} ${resolveBackgroundColor()}`}>
        <Item item={data[activeIndex]} />
      </div>
      <PixelatedBorder />
    </div>
  );
};

const Item = ({ item }) => {
  return (
    <div className={styles.item} key={item.quote}>
      <Text>{item.quote}</Text>
      <div className="pt-2" />
      <Text tag="subHeader">{item.name}</Text>
    </div>
  );
};

Testimonial.propTypes = {
  data: PropTypes.array.isRequired,
};

Testimonial.defaultProps = {
  data: [],
};

export default Testimonial;
