import styles from "./illustration-section.module.scss";
import Carousel from "@/components/carousel";
import ImageWithBall from "@/components/image-with-ball";


const IllustrationSection = ({
     illustrationQuote,
     illustrationQuoteTitle,
     mascot,
     illustrationQuoteListLink,
     backgroundImageWithBallAndText
}) => {

return (
    <section className={styles.containerSection}>
        <div className={`${styles.illustrationSectionPadding} ${styles.marginTop}`}>
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <section className={styles.illustrationSectionLeft}>
                            {!!illustrationQuote && <Carousel
                                data={illustrationQuote}
                                title={illustrationQuoteTitle}
                                image={mascot}
                                illustrationQuoteListLink={illustrationQuoteListLink}
                            />}
                        </section>
                    </div>
                    <div className="col-sm-6">
                        <section className={styles.rightSection}>
                            <ImageWithBall {...backgroundImageWithBallAndText} />
                        </section>
                    </div>
                </div>
            </div>
        </div>
    </section>
);
};

export default IllustrationSection;
