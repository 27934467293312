import PropTypes from "prop-types";
import styles from "./animated.module.scss";

const Animated = ({ animation, delay, children }) => {
  return (
    <div
      className={
        styles[animation] + (delay ? " " + styles["delay-" + delay] : "")
      }
    >
      {children}
    </div>
  );
};

Animated.propTypes = {
  animation: PropTypes.oneOf(["letter", "slideInLeft", "slideInDown"]),
  delay: PropTypes.number,
};

Animated.defaultProps = {
  animation: "slideInLeft",
};

export default Animated;
