import usePollenData from 'hooks/usePollenData';
import styles from './PollenForecast.module.scss';
import { useState } from 'react';
import Loader from '../loader';

const RegionSelect = ({ regions, setter, selected }) => {
	return (
		<div className={styles.forecast_region}>
			<span>Välj stad: </span>
			<select onChange={setter} defaultValue={selected}>
				{regions.map((region) => {
					return (
						<option key={region.id} value={region.id}>
							{region.name}
						</option>
					);
				})}
			</select>
		</div>
	);
};

const StackedBarGraph = ({ level, titel, pollen }) => {
	if (level === 0) {
		return (
			<div>
				<p>{pollen}</p>
				<p>Inga halter</p>
			</div>
		);
	} else {
		return (
			<div className={`${styles.forecast_bar}`} data-value={level}>
				<p>{pollen}&nbsp;</p>
				<p className="fs-6">{titel} halter.</p>
			</div>
		);
	}
};

const ShowRegionData = ({ weekday = 0, hook }) => {
	if (hook.data.length > 0) {
		return (
			<div className={`container h-100 mt-4 `}>
				<div className={`${styles.forecast_data_container}`}>
					{hook.data.map((item, index) => {
						if (!item.levelSeries[weekday]?.level) {
							<div key={index} className="col align-self-center"></div>;
						} else {
							return (
								<div key={index} className={`${styles.forecast_data}`}>
									{item.levelSeries[weekday].level > 0 && (
										<StackedBarGraph
											level={item.levelSeries[weekday].level}
											titel={hook.getHumanReadablePollenLevel(
												item.levelSeries[weekday].level,
											)}
											pollen={hook.getPollenNameById(item.pollenId)}
										/>
									)}
								</div>
							);
						}
					})}
				</div>
			</div>
		);
	}
	return <></>;
};

const ShowWeekDays = ({ weekdays, activeDay, setActiveDay }) => {
	return (
		<div className={styles.forecast_weekday_container}>
			{weekdays.map((day, index) => {
				let text = '';
				if (index === 0) {
					text = 'Idag';
				} else if (index === 1) {
					text = 'Imorgon';
				} else {
					text = day;
				}

				return (
					<div
						role="button"
						key={day}
						onClick={() => setActiveDay(index)}
						data-value={activeDay === index ? 'active' : 'notactive'}
					>
						<p>{text}</p>
					</div>
				);
			})}
		</div>
	);
};

const PollenForecast = ({ show, hide }) => {
	const pollen = usePollenData();
	const [activeDay, setActiveDay] = useState(0);

	if (process.env.NEXT_PUBLIC_POLLEN_REPORT_ENABLED) {
		return (
			<>
				<div className={`${styles.forecast} ${show ? styles.slideInDown : ''}`}>
					<div className={styles.forecast_close} onClick={() => hide()}>
						<span></span>
						<span></span>
						<span></span>
					</div>
					<h2 className="text-center">Pollenprognos</h2>
					<div className="row mb-2 mt-4">
						<RegionSelect
							regions={pollen.regions}
							setter={(e) => {
								pollen.setRegion(e.currentTarget.value);
								setActiveDay(0);
							}}
							selected={pollen.selectedRegion}
						/>
					</div>
					{pollen.pending && (
						<div className="row">
							<Loader />
						</div>
					)}
					{!pollen.pending && pollen.data.length === 0 && (
						<div>
							<h2 className="text-center">Pollenprognos inte tillgänglig</h2>
						</div>
					)}
					{!pollen.pending && pollen.data.length > 0 && (
						<div className={styles.fadeIn}>
							<div className="row">
								<ShowWeekDays
									weekdays={pollen.weekdays}
									activeDay={activeDay}
									setActiveDay={setActiveDay}
								/>
							</div>
							<div className="row">
								<ShowRegionData weekday={activeDay} hook={pollen} />
							</div>
						</div>
					)}
					<p className={styles.forecast_copyright}>
						Data h&auml;mtas från Palynologiska laboratoriet vid Naturhistoriska
						riksmuseet
					</p>
				</div>
				<div className={styles.forecast_empty} onClick={() => hide()}></div>
			</>
		);
	} else {
		return <></>;
	}
};

export default PollenForecast;
