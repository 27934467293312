import PropTypes from "prop-types";
import styles from "./loader.module.scss";

const Loader = ({ color, duration }) => {
  return (
    <div className={styles.root}>
      <svg viewBox="0 0 135 140" fill={color}>
        <rect y="10" width="15" height="120" rx="6">
          <animate
            attributeName="height"
            begin={duration / 2 + "ms"}
            dur={duration + "ms"}
            values="120;110;100;90;80;70;60;50;40;140;120"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin={duration / 2 + "ms"}
            dur={duration + "ms"}
            values="10;15;20;25;30;35;40;45;50;0;10"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="30" y="10" width="15" height="120" rx="6">
          <animate
            attributeName="height"
            begin={duration / 4 + "ms"}
            dur={duration + "ms"}
            values="120;110;100;90;80;70;60;50;40;140;120"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin={duration / 4 + "ms"}
            dur={duration + "ms"}
            values="10;15;20;25;30;35;40;45;50;0;10"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="60" width="15" height="140" rx="6">
          <animate
            attributeName="height"
            begin="0s"
            dur={duration + "ms"}
            values="120;110;100;90;80;70;60;50;40;140;120"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin="0s"
            dur={duration + "ms"}
            values="10;15;20;25;30;35;40;45;50;0;10"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="90" y="10" width="15" height="120" rx="6">
          <animate
            attributeName="height"
            begin={duration / 4 + "ms"}
            dur={duration + "ms"}
            values="120;110;100;90;80;70;60;50;40;140;120"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin={duration / 4 + "ms"}
            dur={duration + "ms"}
            values="10;15;20;25;30;35;40;45;50;0;10"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
        <rect x="120" y="10" width="15" height="120" rx="6">
          <animate
            attributeName="height"
            begin={duration / 2 + "ms"}
            dur={duration + "ms"}
            values="120;110;100;90;80;70;60;50;40;140;120"
            calcMode="linear"
            repeatCount="indefinite"
          />
          <animate
            attributeName="y"
            begin={duration / 2 + "ms"}
            dur={duration + "ms"}
            values="10;15;20;25;30;35;40;45;50;0;10"
            calcMode="linear"
            repeatCount="indefinite"
          />
        </rect>
      </svg>
    </div>
  );
};

Loader.propTypes = {
  color: PropTypes.string,
  duration: PropTypes.number,
};

Loader.defaultProps = {
  color: "#000000",
  duration: 1000,
};

export default Loader;
