import styles from "./hero-promo-kollar.module.scss";
import Link from "@/components/link";
import Image from "@/components/image";
import { useDeviceDetect } from "hooks";

const HeroPromoKollar = ({ config }) => {
	const { isSmallScreen } = useDeviceDetect();

	const resolveBackgroundColor = () => {
		if (config.heroSection.backgroundColor?.toLowerCase() === "primary") {
			return styles.primaryBackgroundColor;
		} else if (config.heroSection.backgroundColor?.toLowerCase() === "secondary") {
			return styles.secondaryBackgroundColor;
		} else if (config.heroSection.backgroundColor?.toLowerCase() === "tertiary") {
			return styles.tertiaryBackgroundColor;
		} else {
			return styles.primaryBackgroundColor;
		}
	};

	return (
		<section className={`${styles.heroPromoKollar} ${resolveBackgroundColor()}`}>
			<div className="container">
				<div className="row">
					<div className={styles.promoContainer}>
						{config.configuration.globalKollarnaLinks.map((link) => (
							<a href={link.link} key={link.id}>
								<Link href={"/vara-kollar"} text={link.title} variant="children">
									<Image
										src={link.symbolMobileLogo?.url}
										alt={link.title}
										height={isSmallScreen ? 32 : 48}
									/>
								</Link>
							</a>
						))}
					</div>
					{!isSmallScreen && <hr className={styles.divider} />}
				</div>
			</div>
		</section>
	);
};

export default HeroPromoKollar;
