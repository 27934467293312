import PropTypes from "prop-types";
import styles from "./image-card-quote.module.scss";
import Text from "@/components/text";
import Image from "../image";
import ReadMoreButton from "@/components/read-more-button";


const ImageCardQuote = ({
  heading,
  heading2,
  bodyContent,
  bodyContent2,
  listLinkText,
  listLinkURL,
  //listLinkInNewTab,
  quoteReference,
  quoteImage,
  imageOnRight,
  textColor,
  backgroundColor
}) => {
  const textualContent = () => {
    return (
      <div className={styles.textualContent}>
        <Text tag="h2" variant="h1">
          {heading}
        </Text>
        <Text>{bodyContent}</Text>
          {!!listLinkText && <ReadMoreButton
              url={listLinkURL}
              text={listLinkText}
              textColor={textColor}
          />}
      </div>
    );
  };

  const doctorContent = () => {
    return (
        <div className={styles.doctorContainer}>
          <div className={styles.doctorContainerLeft}>
              <Image rounded width={80} height={80} image={quoteImage} className="image" alt="Doktor" />
          </div>
          <div className={styles.doctorContainerRight}>
              <Text tag="subHeader">{quoteReference}</Text>
          </div>
        </div>
    )
  }

  const imageContent = () => {
    return (
      <div className={`${styles.textualContent}`}>
        <Text tag="h2" variant="h1">
          {heading2}
        </Text>
        <Text>{bodyContent2}</Text>
          {doctorContent()}
      </div>
    );
  };

  const resolveBackgroundColor = () => {
    if (backgroundColor?.toLowerCase() === "primary") {
      return styles.backgroundColorPrimary;
    } else if (backgroundColor?.toLowerCase() === "secondary") {
      return styles.backgroundColorSecondary;
    } else if (backgroundColor?.toLowerCase() === "tertiary") {
      return styles.backgroundColorTertiary;
    }
  }

  const resolveTextColor = () => {
    if (textColor?.toLowerCase() === "white") {
      return styles.textColorWhite;
    } else if (textColor?.toLowerCase() === "black") {
      return styles.textColorBlack;
    }
  }

  return (
    <section className={`${styles.section} ${resolveBackgroundColor()} ${resolveTextColor()} ${styles.marginTop}`}>
      <div className={`container ${styles.layout}`}>
        <div className="row">
          <div className="col-sm-6">
            <section className={styles.imageContainer}>
              {!imageOnRight && imageContent()}
              {imageOnRight && textualContent()}
            </section>
          </div>
          <div className="col-sm-6">
              <section className={styles.imageContainer}>
                {imageOnRight && imageContent()}
                {!imageOnRight && textualContent()}
              </section>
          </div>
        </div>
      </div>
    </section>
  );
};

ImageCardQuote.propTypes = {
  heading: PropTypes.string.isRequired,
  bodyContent: PropTypes.string.isRequired,
  quoteReference: PropTypes.string.isRequired,
  quoteImage: PropTypes.object.isRequired,
  imageOnRight: PropTypes.bool,
};

export default ImageCardQuote;
