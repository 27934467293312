import { useDeviceDetect } from "hooks";
import PropTypes from "prop-types";
import Text from "../text";
import Link from "../link";
import styles from "./image-with-ball.module.scss";
import Image from "../image";

const ImageWithBall = ({ background, ball, text, slug, textColor }) => {
  const { isSmallScreen } = useDeviceDetect();
  const content = (
    <div className={styles.imageWithBall}>
      <Image
        className={styles.background}
        src={background.url}
        alt={background.alt ?? background.name}
      />
      <div className={styles.ballContainer}>
        <Image
          className={styles.ball}
          src={ball.url}
          alt={ball.alt ?? ball.name}
        />
        <div className={`${styles.textContainer} 
        ${textColor?.toLowerCase() === "white" ? styles.whiteTextColor : styles.blackTextColor}`}>
          <Text
            tag="body"
            variant={isSmallScreen ? "body" : "h2"}
            align="center"
          >
            {text}
          </Text>
          {textColor?.toLowerCase() === "black" ? <Image
              src="/icons/arrow-right.svg"
              width={32}
              height={32}
              alt="Pil höger"
          /> : <Image
              src="/icons/arrow-right-white.svg"
              width={32}
              height={32}
              alt="Pil höger"
          />}
        </div>
      </div>
    </div>
  );

  if (slug) {
    return (
      <Link variant="children" href={slug}>
        {content}
      </Link>
    );
  } else {
    return content;
  }
};

ImageWithBall.propTypes = {
  background: PropTypes.object.isRequired,
  ball: PropTypes.object.isRequired,
  text: PropTypes.string.isRequired,
  slug: PropTypes.string,
};

export default ImageWithBall;
